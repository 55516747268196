import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { X, ArrowLeft, Plus, Minus, Trash2 } from "lucide-react";
import { Link, useLocation } from "react-router-dom";
import {
  updateItemQuantity,
  removeFromCart,
  clearCart,
} from "../store/cartSlice";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

// Animation Variants
const pageVariants = {
  initial: { opacity: 0, x: "100%" },
  in: { opacity: 1, x: 0 },
  out: { opacity: 0, x: "-100%" },
};

const pageTransition = {
  type: "tween",
  ease: "anticipate",
  duration: 0.5,
};

const itemVariants = {
  hidden: { opacity: 0, x: -50 },
  visible: (custom) => ({
    opacity: 1,
    x: 0,
    transition: {
      delay: custom * 0.1,
      type: "spring",
      stiffness: 100,
    },
  }),
};

const AdminCart = ({ isOpen, onClose, menuItems }) => {
  const location = useLocation();
  const shouldRenderCart = location.pathname === "/admin";

  const user = useSelector((state) => state.user?.user?.userDetails);
  const [formData, setFormData] = useState({
    name: user ? user.name : "",
    email: user ? user.email : "",
    phone: user ? user.phone_number : "",
    address: {
      street: user ? user.street_address : "POS ORDER",
      city: user ? user.city : "POS ORDER",
      state: user ? user.county : "POS ORDER",
      zipCode: user ? user.postal_code : "POS ORDER",
    },
    deliveryOption: "POS ORDER",
    paymentOption: "POS ORDER",
    reviewNotes: "",
  });
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);

  const cart = useSelector((state) => state.cart.items);
  const dispatch = useDispatch();

  const totalPrice = cart.reduce(
    (total, item) => total + parseFloat(item.totalPrice * item.itemQuantity),
    0
  );

  const handleIncreaseQuantity = (title) => {
    dispatch(updateItemQuantity({ title: title, change: 1 }));
  };

  const handleDecreaseQuantity = (title, currentQuantity) => {
    if (currentQuantity > 1) {
      dispatch(updateItemQuantity({ title: title, change: -1 }));
    }
  };

  const handleRemoveItem = (title) => {
    dispatch(removeFromCart(title));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.includes("address.")) {
      const addressField = name.split(".")[1];
      setFormData((prev) => ({
        ...prev,
        address: {
          ...prev.address,
          [addressField]: value,
        },
      }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    setProcessing(true);

    try {
      // Step 3: Check if User Exists in Database
      let userId = null;
      let guestId = null;

      try {
        if (user) {
          userId = user.user_id;
        } else {
          // Create a guest entry if the user doesn't exist
          const guestResponse = await axios.post(
            "https://thevillage-backend.onrender.com/users/create-guest",
            {
              name: formData.name,
              email: formData.email,
              phone_number: formData.phone,
              street_address: formData.address.street,
              city: formData.address.city,
              county: formData.address.state,
              postal_code: formData.address.zipCode,
            }
          );

          if (guestResponse.data && guestResponse.data.guest_id) {
            guestId = guestResponse.data.guest_id;
          }
        }
      } catch (userError) {
        console.error("Error checking user existence:", userError);
        setError("User verification failed. Please try again.");
        return;
      }

      // Step 4: Insert Order into Database
      let orderId;
      try {
        const transactionId = Math.floor(
          100000000000 + Math.random() * 900000000000
        ).toString();

        const orderResponse = await axios.post(
          "https://thevillage-backend.onrender.com/orders/create",
          {
            user_id: userId,
            guest_id: guestId,
            transaction_id: transactionId,
            payment_type: formData.paymentOption,
            order_type: formData.deliveryOption,
            total_price: totalPrice,
            extra_notes: formData.reviewNotes || "",
          }
        );

        if (orderResponse.data && orderResponse.data.order_id) {
          orderId = orderResponse.data.order_id;
        } else {
          throw new Error("Failed to create order.");
        }
      } catch (orderError) {
        console.error("Order creation error:", orderError);
        setError("Order creation failed. Please try again.");
        return;
      }

      // Step 5: Insert Order Items into Database
      try {
        await Promise.all(
          cart.map(async (item) => {
            await axios.post(
              "https://thevillage-backend.onrender.com/orders/add-item",
              {
                order_id: orderId,
                item_id: item.id,
                quantity: item.itemQuantity,
                description: item.description,
                total_price: item.totalPrice,
              }
            );
          })
        );

        // Clear cart after successful order
        dispatch(clearCart());
        onClose();
        // You could add a success message here
      } catch (orderItemError) {
        console.error("Order item insertion error:", orderItemError);
        setError("Some items could not be recorded. Please try again.");
      }
    } catch (err) {
      console.error("Order Error:", err);
      setError(err.message || "Order failed. Please try again.");
    } finally {
      setProcessing(false);
    }
  };

  const renderCartItems = () => (
    <>
      {cart.map((item, index) => (
        <motion.div
          key={index}
          custom={index}
          initial="hidden"
          animate="visible"
          variants={itemVariants}
          className="flex justify-start items-center border-b py-2 md:py-4"
        >
          <div className="grid grid-cols-7">
            <div className="col-span-1 flex items-center">
              <img
                src={`${item.image}`}
                alt={item.title}
                className="w-auto h-30 object-cover mr-4 rounded"
              />
            </div>
            <div className="col-span-5 flex justify-start items-center">
              <div className="grid grid-cols-1 px-4">
                <h1
                  className="font-semibold text-xs md:text-lg text-start"
                  style={{ fontFamily: "Bambino", fontWeight: 450 }}
                >
                  {item.title}
                </h1>
                <div className="flex items-center space-x-2 my-1">
                  <button
                    onClick={() =>
                      handleDecreaseQuantity(item.title, item.itemQuantity)
                    }
                    className="p-1 rounded-full hover:bg-gray-100"
                  >
                    <Minus size={16} className="text-gray-600" />
                  </button>
                  <span className="text-gray-600 text-sm">
                    {item.itemQuantity}
                  </span>
                  <button
                    onClick={() => handleIncreaseQuantity(item.title)}
                    className="p-1 rounded-full hover:bg-gray-100"
                  >
                    <Plus size={16} className="text-gray-600" />
                  </button>
                  <button
                    onClick={() => handleRemoveItem(item.title)}
                    className="p-1 rounded-full hover:bg-gray-100 ml-2"
                  >
                    <Trash2 size={16} className="text-red-500" />
                  </button>
                </div>
                <p className="text-gray-600 text-start text-xxxs md:text-sm">
                  {item.description.split("\n").map((line, index) => (
                    <span key={index}>
                      {line}
                      <br />
                    </span>
                  ))}
                </p>
              </div>
            </div>
            <div className="col-span-1 flex items-center">
              <p className="text-xs md:text-base font-bold">
                £{(item.totalPrice * item.itemQuantity).toFixed(2)}
              </p>
            </div>
          </div>
        </motion.div>
      ))}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5 }}
        className="mt-6 flex justify-between items-center"
      >
        <h3 className="text-xl font-bold">Total</h3>
        <p className="text-xl font-bold">£{totalPrice.toFixed(2)}</p>
      </motion.div>
    </>
  );

  const renderCustomerInfoForm = () => (
    <motion.div
      initial={{ opacity: 0, x: 50 }}
      animate={{ opacity: 1, x: 0 }}
      className="space-y-4"
    >
      <div>
        <label className="block mb-1 md:mb-2 text-xs md:text-sm text-start">
          Full Name
        </label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
          className="w-full p-0 md:p-2 border rounded text-sm md:text-sm"
        />
      </div>
      <div>
        <label className="block mb-1 md:mb-2 text-xs md:text-sm text-start">
          Email
        </label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
          className="w-full p-0 md:p-2 border rounded text-sm md:text-sm"
        />
      </div>
      <div>
        <label className="block mb-1 md:mb-2 text-xs md:text-sm text-start">
          Phone Number
        </label>
        <input
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          required
          className="w-full p-0 md:p-2 border rounded text-sm md:text-sm"
        />
      </div>
      {/* <div>
        <label className="block mb-1 md:mb-2 text-xs md:text-sm text-start">
          Street Address
        </label>
        <input
          type="text"
          name="address.street"
          value={formData.address.street}
          onChange={handleChange}
          required
          className="w-full p-0 md:p-2 border rounded text-sm md:text-sm"
        />
      </div>
      <div className="grid grid:cols-2 md:grid-cols-3 gap-4">
        <div>
          <label className="block mb-1 md:mb-2 text-xs md:text-sm text-start">
            City
          </label>
          <input
            type="text"
            name="address.city"
            value={formData.address.city}
            onChange={handleChange}
            required
            className="w-full p-0 md:p-2 border rounded text-sm md:text-sm"
          />
        </div>
        <div>
          <label className="block mb-1 md:mb-2 text-xs md:text-sm text-start">
            County
          </label>
          <input
            type="text"
            name="address.state"
            value={formData.address.state}
            onChange={handleChange}
            required
            className="w-full p-0 md:p-2 border rounded text-sm md:text-sm"
          />
        </div>
        <div>
          <label className="block mb-1 md:mb-2 text-xs md:text-sm text-start">
            Postal Code
          </label>
          <input
            type="text"
            name="address.zipCode"
            value={formData.address.zipCode}
            onChange={handleChange}
            required
            className="w-full p-0 md:p-2 border rounded text-sm md:text-sm"
          />
        </div>
      </div>

      <div>
        <label className="block mb-1 text-xs md:text-sm">Delivery Option</label>
        <div className="flex space-x-4 text-xs md:text-sm">
          <label>
            <input
              type="radio"
              name="deliveryOption"
              value="delivery"
              checked={formData.deliveryOption === "delivery"}
              onChange={handleChange}
            />{" "}
            Delivery
          </label>
          <label>
            <input
              type="radio"
              name="deliveryOption"
              value="pickup"
              checked={formData.deliveryOption === "pickup"}
              onChange={handleChange}
            />{" "}
            Pick Up
          </label>
        </div>
      </div>

      <div>
        <label className="block mb-1 text-xs md:text-sm">Payment Option</label>
        <div className="flex space-x-4 text-xs md:text-sm">
          <label>
            <input
              type="radio"
              name="paymentOption"
              value="cod"
              checked={formData.paymentOption === "cod"}
              onChange={handleChange}
            />{" "}
            Cash on Delivery
          </label>
          <label>
            <input
              type="radio"
              name="paymentOption"
              value="online"
              checked={formData.paymentOption === "online"}
              onChange={handleChange}
            />{" "}
            Online Payment
          </label>
        </div>
      </div> */}
      {/* Review Notes */}
      <div>
        <label className="block mb-1 md:mb-2 text-xs md:text-sm text-start">
          Review Notes
        </label>
        <textarea
          name="reviewNotes"
          value={formData.reviewNotes}
          onChange={handleChange}
          className="w-full p-2 border rounded text-sm md:text-sm"
          rows="3"
        />
      </div>
    </motion.div>
  );

  return (
    <AnimatePresence>
      {isOpen && shouldRenderCart && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0  z-50 flex justify-end md:mt-16 rounded-lg shadow-xl"
          style={{ pointerEvents: "none" }}
        >
          <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}
            className="bg-white w-4/5 md:w-[25%] h-full p-6 overflow-y-auto rounded-lg shadow-xl"
            style={{ pointerEvents: "auto", backgroundColor: "#F6F5F5" }}
          >
            <div className="bg-[#AA1B17] -mx-6 -mt-6 mb-6 p-4 flex items-center block md:hidden">
              <motion.h2
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                className="text-base md:text-2xl font-bold text-white flex-grow text-start md:text-center"
                style={{ fontFamily: "Bambino", fontWeight: 450 }}
              >
                CUSTOMER INFORMATION
              </motion.h2>
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={onClose}
                className="text-white hover:text-gray-200"
              >
                <X size={24} />
              </motion.button>
            </div>

            <form onSubmit={handleSubmit}>
              {cart.length === 0 ? (
                <motion.p
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="text-center text-gray-500"
                >
                  ITEMS TO BE SHOWN HERE
                </motion.p>
              ) : (
                <>
                  {renderCartItems()}
                  {renderCustomerInfoForm()}
                  {error && <div className="text-red-500 mt-4">{error}</div>}
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    type="submit"
                    disabled={processing}
                    className="w-full bg-[#AA1B17] text-white text-sm md:text-base py-1 md:py-3 mt-6 rounded-lg hover:bg-red-700 transition"
                  >
                    {processing ? "Processing..." : "Place Order"}
                  </motion.button>
                </>
              )}
            </form>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default AdminCart;
