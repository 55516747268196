import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import NavBar from "./Components/NavBar";
import HomePage from "./Components/HomePage";
import Menu from "./Components/Menu";
import Footer from "./Components/Footer";
import ContactUs from "./Components/ContactUs";
import ScrollToTop from "./Components/ScrollToTop";
import AdminLogin from "./Components/AdminLogin";
import AdminDashboard from "./Components/AdminDashboard";
import localMenuItems from "./menuItems.json";

function App() {
  const [menuItems, setMenuItems] = useState(localMenuItems || []);

  const [isAdmin, setIsAdmin] = useState(false);
  const [offers, setOffers] = useState([]);

  useEffect(() => {
    const fetchMenuItems = async () => {
      try {
        const response = await fetch(
          "https://thevillage-backend.onrender.com/item/items"
        );
        const data = await response.json();
        console.log(data);
        const sortedData = data.sort((a, b) => a.title.localeCompare(b.title));

        console.log(sortedData);
        setMenuItems(sortedData);
      } catch (error) {
        console.error("Error fetching menu items:", error);
      }
    };
    const fetchOffers = async () => {
      try {
        const response = await fetch(
          "https://thevillage-backend.onrender.com/admin/offers"
        );
        const offersData = await response.json();
        console.log("Offers:", offersData);
        setOffers(offersData);
      } catch (error) {
        console.error("Error fetching offers:", error);
      }
    };

    fetchMenuItems();
    fetchOffers();
  }, []);

  return (
    <Router>
      <AppContent
        isAdmin={isAdmin}
        setIsAdmin={setIsAdmin}
        menuItems={menuItems}
        offers={offers}
      />
    </Router>
  );
}

function AppContent({ isAdmin, setIsAdmin, menuItems, offers }) {
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith("/admin");

  return (
    <div className="App">
      {!isAdminRoute && <NavBar />}
      <Routes>
        {/* <Route path="/" element={<HomePage />} /> */}
        <Route
          path="/"
          element={<Menu menuItems={menuItems} offers={offers} />}
        />
        <Route path="/contact" element={<ContactUs />} />
        <Route
          path="/admin"
          element={
            isAdmin ? (
              <AdminDashboard menuItems={menuItems} />
            ) : (
              <AdminLogin setIsAdmin={setIsAdmin} />
            )
          }
        />
      </Routes>
      <ScrollToTop />
      {!isAdminRoute && <Footer />}
    </div>
  );
}

export default App;
