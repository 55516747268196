import React from "react";
import background from "../images/Pizza1.jpeg";

function AdminItemCard(props) {
  const TitleTextStyle = {
    fontFamily: "Bambino",
    fontWeight: 550,
  };
  const DescTextStyle = {
    fontFamily: "Bambino",
    fontWeight: 350,
  };
  const StartingTextStyle = {
    fontFamily: "Bambino",
    fontWeight: 450,
  };
  const PriceTextStyle = {
    fontFamily: "Bambino",
    fontWeight: 550,
    color: "#074711",
  };
  const AddCartTextStyle = {
    fontFamily: "Bambino",
    fontWeight: 450,
    backgroundColor: "#AA1B17",
    height: "30px",
  };

  // Extract the first price if it's an object
  let startingPrice;
  if (typeof props.menuItems.price === "object") {
    const firstKey = Object.keys(props.menuItems.price)[0]; // Get first key
    startingPrice = props.menuItems.price[firstKey]; // Get first price value
  } else {
    startingPrice = props.menuItems.price; // If it's a single value, use it directly
  }

  return (
    <div>
      <div
        className="relative max-w-sm rounded-lg shadow-xl h-[260px] md:h-[80px] "
        style={{ backgroundColor: "#F6F5F5" }}
      >
        <div className="px-2 md:px-4 py-1  grid grid-cols-10 items-center">
          <div className="col-span-8">
            <div
              className="text-xs md:text-xs py-2 md:py-4 h-[60px] md:h-[70px] flex justify-start text-start items-center"
              style={TitleTextStyle}
            >
              {props.menuItems.title}
            </div>
            {/* <p
              className="text-xxxs md:text-xs  h-[50px] flex justify-start text-start"
              style={DescTextStyle}
            >
              {props.menuItems.description}
            </p> */}
          </div>

          <div className="flex justify-center col-span-2 items-center">
            <button
              onClick={props.onAddToCart}
              className="flex w-2/3 items-center justify-center text-white py-1 text-xxs md:text-sm focus:outline-none my-2 md:my-4 rounded-lg"
              style={AddCartTextStyle}
            >
              +
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminItemCard;
