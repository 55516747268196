import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import AdminItemCard from "./AdminItemCard";
import CartModal from "./CartModal";
import AdminCart from "./AdminCart";
import { useDispatch } from "react-redux";

function AdminMenu({ menuItems }) {
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("Pizza");
  const [direction, setDirection] = useState(0);
  const [visibleStartIndex, setVisibleStartIndex] = useState(0);
  const [totalShown, setTotalShown] = useState(3);
  const [cartModalItem, setCartModalItem] = useState(null);
  const [iconSize, setIconSize] = useState(32);

  const dispatch = useDispatch();

  useEffect(() => {
    const updateTotalShown = () => {
      if (window.innerWidth >= 1200) {
        setTotalShown(5); // Desktop
        setIconSize(32);
      } else if (window.innerWidth >= 768) {
        setTotalShown(7); // Tablet
        setIconSize(24);
      } else {
        setTotalShown(4); // Mobile
        setIconSize(12);
      }
    };

    updateTotalShown(); // Set initial state
    window.addEventListener("resize", updateTotalShown);

    return () => {
      window.removeEventListener("resize", updateTotalShown);
    };
  }, []);

  const menuCategories = [
    "Pizza",
    "Shawarma",
    "Burgers",
    "Calzones",
    "GarlicBread",
    "Wraps",
    "KidsMeal",
    "Sides",
    "Drinks",
  ];

  const handleClick = (item) => {
    console.log(menuItems);
    const currentIndex = menuCategories.indexOf(selectedItem);
    const newIndex = menuCategories.indexOf(item);

    setDirection(newIndex > currentIndex ? 1 : -1);
    setSelectedItem(item);
  };

  const MenuTextStyle = {
    fontFamily: "Bambino",
    fontWeight: 550,
    marginTop: "5px",
    transition: "all 0.3s ease",
  };

  const MenuTextStyleHover = {
    ...MenuTextStyle,
    backgroundColor: "#AA1B17",
    borderRadius: "15px",
    color: "white",
  };

  const imageVariants = {
    enter: (direction) => ({
      x: direction > 0 ? 100 : -100,
      opacity: 0,
    }),
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: (direction) => ({
      zIndex: 0,
      x: direction < 0 ? 100 : -100,
      opacity: 0,
    }),
  };

  const imageTransition = {
    x: { type: "spring", stiffness: 300, damping: 30 },
    opacity: { duration: 0.2 },
  };

  const handlePrevPage = () => {
    setVisibleStartIndex((prev) => Math.max(0, prev - 1));
    setDirection(-1);
  };

  const handleNextPage = () => {
    setVisibleStartIndex((prev) =>
      Math.min(menuCategories.length - totalShown, prev + 1)
    );
    setDirection(1);
  };

  const handleOpenCartModal = (item) => {
    setCartModalItem(item);
  };

  const handleCloseCartModal = () => {
    setCartModalItem(null);
  };
  const sliderRef = useRef(null);

  const handleMouseMove = (e) => {
    if (window.innerWidth >= 1024) {
      // Only enable on desktop
      const slider = sliderRef.current;
      if (slider) {
        const { left, width } = slider.getBoundingClientRect();
        const mouseX = e.clientX - left;
        const move = (mouseX / width - 0.5) * 200; // Adjust scroll sensitivity
        slider.style.transform = `translateX(${move}px)`;
      }
    }
  };

  const renderCategoryImages = () => {
    return (
      <motion.div
        className="flex cursor-grab w-[400px] md:w-[1080px]"
        drag="x"
        dragConstraints={{
          left: -((menuCategories.length - totalShown) * 120),
          right: 0,
        }}
        whileTap={{ cursor: "grabbing" }}
        initial={{ x: 0 }} // Ensures it starts from the first category
        animate={{ x: 0 }}
        style={{
          // width: menuCategories.length * 120, // Ensures all items fit
          paddingLeft: "10px", // Add padding to avoid cutting
          paddingRight: "10px", // Same on the right
        }}
      >
        {menuCategories.map((category) => (
          <div
            key={category}
            className="flex flex-col items-center min-w-[95px] md:min-w-[160px] mx-1 md:mx-2"
            onClick={() => handleClick(category)}
            style={{ cursor: "pointer" }}
          >
            <div
              className="text-xxs md:text-base text-center px-2"
              style={
                selectedItem === category ? MenuTextStyleHover : MenuTextStyle
              }
            >
              {category.toUpperCase()}
            </div>
          </div>
        ))}
      </motion.div>
    );
  };

  return (
    <>
      <div className="grid grid-cols-4">
        <div className="mt-8 col-span-4 md:col-span-3 ">
          <div className="flex items-center justify-center relative">
            \
            <AnimatePresence initial={false} custom={direction}>
              return (
              <div
                className="fixed  md:w-[75%]"
                style={{
                  backgroundColor: "#FFFFFF",
                  zIndex: "20",
                  marginTop: "3.8rem",
                }}
              >
                <div
                  className="overflow-x-auto no-scrollbar flex justify-center overflow-y-hidden mb-4"
                  style={{ scrollbarWidth: "none" }}
                >
                  {renderCategoryImages()}
                </div>
              </div>
              );
            </AnimatePresence>
          </div>
          <AnimatePresence custom={direction}>
            <motion.div
              key={selectedItem}
              custom={direction}
              initial="initial"
              animate="in"
              exit="out"
              variants={{
                initial: (direction) => ({
                  x: direction > 0 ? 1000 : -1000,
                  opacity: 0,
                }),
                in: {
                  x: 0,
                  opacity: 1,
                },
                out: (direction) => ({
                  x: direction > 0 ? -1000 : 1000,
                  opacity: 0,
                }),
              }}
              transition={{
                type: "tween",
                ease: "anticipate",
                duration: 0.5,
              }}
              className="grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-8 mx-4 md:mx-20 mt-16"
              style={{ position: "relative", zIndex: "10" }}
            >
              {menuItems.map(
                (item) =>
                  item.Type === selectedItem && (
                    <div key={item.id} className="col-span-1">
                      <AdminItemCard
                        menuItems={item}
                        onAddToCart={() => handleOpenCartModal(item)}
                      />
                    </div>
                  )
              )}
            </motion.div>
          </AnimatePresence>

          {cartModalItem && (
            <CartModal
              isOpen={!!cartModalItem}
              onClose={handleCloseCartModal}
              item={cartModalItem}
            />
          )}
        </div>
        {/* Cart Component */}
        <div className="hidden md:block col-span-1 ">
          {/* <Elements stripe={stripePromise}> */}
          <AdminCart
            isOpen={true}
            onClose={() => setIsCartOpen(false)}
            menuItems={menuItems}
          />
          {/* </Elements> */}
        </div>
      </div>
    </>
  );
}

export default AdminMenu;
