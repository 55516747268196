import AdminMenu from "./AdminMenu";
import { useState } from "react";

function AdminDashboard({ menuItems }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("standard");
  const [isShopOpen, setIsShopOpen] = useState(true);
  const [isOpen, setIsOpen] = useState(true);
  const [salesView, setSalesView] = useState("daily");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [pendingState, setPendingState] = useState(null);

  // Handle toggle click - shows confirmation instead of immediate change
  const handleToggleClick = () => {
    setPendingState(!isOpen); // Store the pending state
    setShowConfirmation(true); // Show confirmation dialog
  };

  // Cancel the toggle operation
  const cancelToggle = () => {
    setShowConfirmation(false);
    setPendingState(null);
  };

  // Confirm the toggle operation
  const confirmToggle = () => {
    setIsOpen(pendingState); // Apply the pending state
    setShowConfirmation(false);
    setPendingState(null);
  };

  const TitleTextStyle = {
    fontFamily: "Bambino",
    fontWeight: 550,
    backgroundColor: "#074711",
    zIndex: 50, // Ensures the heading is on top
    position: "fixed", // Keeps the heading in place
    width: "100%",
    top: 0,
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const toggleShop = () => {
    setIsShopOpen(!isShopOpen);
  };

  const handleSalesViewChange = (view) => {
    setSalesView(view);
  };

  return (
    <div className="relative">
      <div
        style={TitleTextStyle}
        className="text-white py-2 flex justify-between items-center px-4"
      >
        <div className="w-10"></div> {/* Empty div for spacing */}
        <h2 className="text-3xl">Admin Dashboard</h2>
        <div className="flex items-center gap-4">
          {/* Toggle switch with Open/Close text */}
          <div className="flex items-center">
            <span className="mr-2 text-sm font-medium">
              {isOpen ? "Open" : "Close"}
            </span>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={isOpen}
                onChange={handleToggleClick}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-600 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-green-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"></div>
            </label>
          </div>

          {/* Settings button */}
          <button
            onClick={toggleModal}
            className="bg-transparent hover:bg-green-700 text-white font-bold py-1 px-2 rounded"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          </button>
        </div>
      </div>

      {/* Confirmation Modal */}
      {showConfirmation && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-sm mx-auto">
            <h3
              className="text-lg font-medium text-gray-900 mb-4"
              style={{ fontFamily: "Bambino", fontWeight: 550 }}
            >
              CONFIRMATION
            </h3>
            <p
              className="text-gray-700 mb-6"
              style={{ fontFamily: "Bambino", fontWeight: 350 }}
            >
              {isOpen
                ? "Are you sure you want to close the shop?"
                : "Are you sure you want to open the shop?"}
            </p>
            <div className="flex justify-end gap-4">
              <button
                onClick={cancelToggle}
                className="px-4 py-2 bg-[#AA1B17] text-gray-800 rounded hover:bg-[#AA1B17]"
                style={{ fontFamily: "Bambino", fontWeight: 450 }}
              >
                Cancel
              </button>
              <button
                onClick={confirmToggle}
                className="px-4 py-2 bg-green-800 text-white rounded hover:bg-green-600"
                style={{ fontFamily: "Bambino", fontWeight: 450 }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}

      <div>
        <AdminMenu menuItems={menuItems} />
      </div>

      {/* Settings Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[100]">
          <div className="bg-white rounded-lg shadow-lg w-full max-w-md">
            {/* Modal Header */}
            <div className="border-b border-gray-200">
              <nav className="flex">
                <button
                  className={`px-4 py-2 font-medium text-sm ${
                    activeTab === "standard"
                      ? "border-b-2 border-green-500 text-green-600"
                      : "text-gray-500 hover:text-gray-700"
                  }`}
                  onClick={() => handleTabChange("standard")}
                >
                  Standard Settings
                </button>
                <button
                  className={`px-4 py-2 font-medium text-sm ${
                    activeTab === "sales"
                      ? "border-b-2 border-green-500 text-green-600"
                      : "text-gray-500 hover:text-gray-700"
                  }`}
                  onClick={() => handleTabChange("sales")}
                >
                  Show Sales
                </button>
              </nav>
            </div>

            {/* Modal Content */}
            <div className="p-4">
              {activeTab === "standard" ? (
                <div className="flex items-center justify-between">
                  <span className="font-medium">Shop Status</span>
                  <label className="inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      value=""
                      className="sr-only peer"
                      checked={isShopOpen}
                      onChange={toggleShop}
                    />
                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-green-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"></div>
                    <span className="ms-3 text-sm font-medium text-gray-700">
                      {isShopOpen ? "Open" : "Closed"}
                    </span>
                  </label>
                </div>
              ) : (
                <div>
                  <h3 className="font-medium mb-3">Sales View</h3>
                  <div className="flex gap-2">
                    <button
                      className={`px-4 py-2 rounded ${
                        salesView === "daily"
                          ? "bg-green-100 text-green-700 border border-green-300"
                          : "bg-gray-100 text-gray-700 border border-gray-300"
                      }`}
                      onClick={() => handleSalesViewChange("daily")}
                    >
                      Daily
                    </button>
                    <button
                      className={`px-4 py-2 rounded ${
                        salesView === "monthly"
                          ? "bg-green-100 text-green-700 border border-green-300"
                          : "bg-gray-100 text-gray-700 border border-gray-300"
                      }`}
                      onClick={() => handleSalesViewChange("monthly")}
                    >
                      Monthly
                    </button>
                    <button
                      className={`px-4 py-2 rounded ${
                        salesView === "yearly"
                          ? "bg-green-100 text-green-700 border border-green-300"
                          : "bg-gray-100 text-gray-700 border border-gray-300"
                      }`}
                      onClick={() => handleSalesViewChange("yearly")}
                    >
                      Yearly
                    </button>
                  </div>
                </div>
              )}
            </div>

            {/* Modal Footer */}
            <div className="border-t border-gray-200 px-4 py-3 flex justify-end">
              <button
                onClick={toggleModal}
                className="bg-green-600 hover:bg-green-700 text-white font-medium py-2 px-4 rounded-md"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminDashboard;
