import { configureStore } from "@reduxjs/toolkit";
import cartReducer from "./cartSlice";
import userReducer from "./userSlice"; // Import user slice
import modalReducer from "./modalSlice";

export const store = configureStore({
  reducer: {
    cart: cartReducer,
    user: userReducer, // Add user reducer
    modal: modalReducer,
  },
});
